@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&family=Roboto:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap');

html {
  font-size: 16px;
}
@media (max-width: 700px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 450px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 350px) {
  html {
    font-size: 12px;
  }
}

@font-face {
  font-family: 'Univers';
  src: url('fonts/univers/Univers-BoldItalic.eot');
  src: url('fonts/univers/Univers-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/univers/Univers-BoldItalic.woff2') format('woff2'),
    url('fonts/univers/Univers-bold-italic.ttf') format('truetype'),
    url('fonts/univers/Univers-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

button,
input,
textarea {
  font-size: 16px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.ReactCollapse--collapse {
  transition: height 500ms ease-out;
  min-height: 50px;
  text-overflow: ellipsis;
}
